import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { A3dnDeleteAlertService } from "a3dn-shared-lib";

@Injectable({
	providedIn: "root",
})
export class AppSignalRService {
	private baseUrl?: string;

	private hubConnection?: signalR.HubConnection;

	startTimeout: any;
	constructor(private a3dnAlertPanel: A3dnDeleteAlertService) {}
	start() {
		let token = localStorage.getItem("jwt");
		if (!token) return;
		this.startConnection(token);
		this.addWebSocketListener();
		if (this.startTimeout) clearTimeout(this.startTimeout);
		this.startTimeout = setTimeout(() => {
			this.start();
		}, 600_000);
	}

	private startConnection(token: string) {
		this.stop();
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(this.baseUrl + "/nouveaudeploiementhub", {
				accessTokenFactory: () => token,
			})
			.build();
		this.hubConnection
			.start()
			.then(() => {})
			.catch((err) => {
				if (err && err?.message?.includes("handshakeRejecter")) {
					console.error(
						"Handshake was rejected. Possible issues: Invalid token, CORS policy, or network problems."
					);
				} else {
					console.log("Error while starting connection: " + err);
				}
			});
	}

	private addWebSocketListener() {
		this.hubConnection?.on("nouveau-deploiement", (data) => {
			this.handleNewRelease();
		});

		this.hubConnection?.onclose((error) => {
			if (error && error?.message?.includes("handshakeRejecter")) {
				console.error("Connection closed due to handshake rejection: ", error);
			} else {
				console.error("Connection closed due to : ", error);
			}
			this.start();
		});
	}

	private handleNewRelease() {
		this.a3dnAlertPanel
			.openModal({
				alertDescription:
					"Une nouvelle version vient d'être déployée. Cliquer sur le bouton Rafraîchir afin de mettre à jour votre navigateur.",
				title: "Nouvelle version",
				confirmButton: "Rafraîchir",
				buttonColor: "primary",
				cancelButton: "Annuler",
			})
			.subscribe((response) => {
				if (response) location.reload();
			});
	}
	setBaseUrl(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	stop() {
		this.hubConnection?.stop();
	}
}
