import { ErrorHandler, NgModule, Type } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ContractHistoryListService, RenewService } from "a3dn-amendment-lib";
import { NewContractAssistantComponent } from "a3dn-contractassistant-lib";
import { A3dnContractRecordLibModule } from "a3dn-contractrecord-lib";
import { RenewContractComponent, RenewStateService } from "a3dn-renewals-lib";
import { NewSettlementComponent } from "a3dn-settlements-lib";
import { A3dnPanelResponseService, PanelWidth, Paneltype } from "a3dn-shared-lib";
import { NewSmsComponent } from "a3dn-sms-lib";
import { NewUnpaidCompanyComponent } from "a3dn-unpaid-lib";
import { Observable } from "rxjs";
import { NewClaimModule } from "ticket-domain-lib";
import { GlobalErrorHandler } from "./global-error-handling/global-error-handler";

@NgModule({
	imports: [
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		A3dnContractRecordLibModule,
		NewClaimModule,
	],
	providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class CoreModule {
	constructor(
		contractService: ContractHistoryListService,
		renewService: RenewStateService,
		renewalAmendmentService: RenewService,
		private a3dnPanel: A3dnPanelResponseService
	) {
		renewService.stopFollowUpContract = contractService.suspendContractTracking.bind(contractService);

		renewService.contractRenewed$ = renewalAmendmentService.contractRenewed$;
		renewService.renewContract = renewalAmendmentService.renewContract.bind(renewalAmendmentService);

		a3dnPanel.openPanel = this.openPanel.bind(this);
	}

	openPanel<T>(panel: Paneltype, value: T, width?: PanelWidth): Observable<string> {
		return this.a3dnPanel.openEditPanel({
			componentName: PANEL_COMPONENT_MAP[panel],
			body: value,
			panelWidth: width || PanelWidth.meduim,
		});
	}
}

const PANEL_COMPONENT_MAP: { [key in Paneltype]: Type<any> } = {
	"new-contract": NewContractAssistantComponent,
	"new-sms": NewSmsComponent,
	"new-unpaid": NewUnpaidCompanyComponent,
	"new-settlement": NewSettlementComponent,
	"new-renewal": RenewContractComponent,
};
