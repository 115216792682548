import { Injectable } from "@angular/core";
import {
	ApplicationInsights,
	IExceptionTelemetry,
	DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApplicationInsightsService {
	private appInsights: ApplicationInsights;

	constructor(private router: Router) {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.AppInsightsInstrumentationKey,
			},
		});
		this.appInsights.loadAppInsights();
		this.loadCustomTelemetryProperties();
		this.createRouterSubscription();
	}

	start() {
		let user = localStorage.getItem("fullname");
		let codeLicence = localStorage.getItem("codeLicence");
		if (user && codeLicence) {
			this.clearUserId();
			this.setUserId(user, codeLicence);
		}
	}
	setUserId(userId: string, accountId: string) {
		this.appInsights.setAuthenticatedUserContext(userId, accountId);
	}

	clearUserId() {
		this.appInsights.clearAuthenticatedUserContext();
	}

	logPageView(name?: string, uri?: string) {
		this.appInsights.trackPageView({
			name,
			uri,
		});
	}

	logException(error: Error) {
		let exception: IExceptionTelemetry = {
			exception: error,
		};
		this.appInsights.trackException(exception);
	}

	private loadCustomTelemetryProperties() {
		this.appInsights.addTelemetryInitializer((envelope) => {
			var item = envelope.baseData;
			item.properties = item.properties || {};
			item.properties["ApplicationPlatform"] = "Web";
			item.properties["ApplicationName"] = "ApplicationName";
		});
	}
	private createRouterSubscription() {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				let urlParts = event.url.split("/");
				this.logPageView(urlParts[2], event.urlAfterRedirects);
			});
	}
}
