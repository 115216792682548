import { ErrorHandler, Injectable } from "@angular/core";
import { IntercepteurErreurDto, TypeErreur } from "../api-client/models";
import { ErreursFrontService } from "../api-client/services";
import { ApplicationInsightsService } from "../application-insights/application-insights.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "../../../environments/environment";
@Injectable({ providedIn: "root" })
export class GlobalErrorHandler implements ErrorHandler {
	activeRoute?: string;
	constructor(
		private erreursFrontService: ErreursFrontService,
		private appInsight: ApplicationInsightsService,
		private router: Router
	) {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.activeRoute = event.url;
			});
	}

	handleError(error: Error): void {
		try {
			if (error instanceof Error) this.frontErrorHandler(error);
			else throw new Error("no specific error type: " + error);
		} catch (error) {}
	}

	frontErrorHandler(error: Error) {
		if (!environment.production) {
			alert("Erreur : " + error.message);
		}
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (
			chunkFailedMessage.test(error.message) ||
			error?.message?.includes("ChunkLoadError: Loading chunk")
		) {
			window.location.reload();
			return;
		}
		if (localStorage.getItem("fullname") && localStorage.getItem("codeLicence"))
			this.appInsight.logException(error);

		if (error?.message?.includes("Handshake was canceled")) return;
		let params: {
			body: IntercepteurErreurDto;
		} = {
			body: {},
		};
		if (error) {
			params.body.description = (
				"route " +
				this.activeRoute +
				" / " +
				" licence code : " +
				localStorage.getItem("codeLicence") +
				" / " +
				error.message.toString()
			).substring(0, 254);
			params.body.exception = error.stack;
			params.body.typeErreur = TypeErreur.AutreFront;
			this.filterErrors(params);
			this.sendErrorToServer(params);
		}
	}

	filterErrors(params: { body: IntercepteurErreurDto }) {
		if (
			params.body.typeErreur === TypeErreur.AutreFront &&
			this.filterContainingStrings(params.body.description, "Handshake")
		)
			return;
		if (
			params.body.typeErreur === TypeErreur.AutreFront &&
			this.filterContainingStrings(params.body.description, "handshake")
		)
			return;
		if (
			params.body.typeErreur === TypeErreur.AutreFront &&
			this.filterContainingStrings(params.body.exception, "connectionClosed") &&
			this.filterContainingStrings(params.body.description, "Uncaught (in promise): [object Undefined]")
		)
			return;
	}

	filterContainingStrings(params: string, term: string): boolean {
		let exist: boolean = false;
		if (params.includes(term)) exist = true;
		return exist;
	}

	sendErrorToServer(params: { body: IntercepteurErreurDto }) {
		this.erreursFrontService.intercepterErreurFrontEnd$Json(params).subscribe(
			(result) => {
				// console.error("result error", JSON.stringify(params.body.description));
			},
			(err) => {
				console.error(" intercepterErreurFrontEnd Error");
			}
		);
	}
}
