/* tslint:disable */
/* eslint-disable */
export enum TypeErreur {
  AutreFront = 'AutreFront',
  QuatreCents = 'QuatreCents',
  QuatreCentsSix = 'QuatreCentsSix',
  QuatreCentsNeuf = 'QuatreCentsNeuf',
  QuatreCentsDixSept = 'QuatreCentsDixSept',
  QuatreCentsVingtQuatre = 'QuatreCentsVingtQuatre',
  CinqCents = 'CinqCents'
}
