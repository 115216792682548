import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class GlobalGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		let canActivate =
			localStorage.getItem("jwt") && localStorage.getItem("statutAbonnement") !== "EssaiExpire";
		if (canActivate) return true;
		this.router.navigate(["login"]);
		return false;
	}
}
