import { EditRolePermissionsComponent, NewAgencyComponent } from "a3dn-authorizations-lib";
import { NewMessageComponent } from "a3dn-emailassistant-lib";
import { NewSettlementComponent } from "a3dn-settlements-lib";
import { IPanelCommandType } from "a3dn-shared-lib";
import { NewSignatureComponent } from "a3dn-signature-lib";
import { NewSmsPanelComponent } from "a3dn-sms-lib";
import { NewEmbeddedSurveyLinkComponent } from "a3dn-surveyconfig-lib";
import { TermsImportComponent } from "a3dn-termsimport-lib";
import { NewUnpaidCompanyComponent } from "a3dn-unpaid-lib";

export const COMMAND_MAPPER: { [key in IPanelCommandType]: any } = {
	Message: NewMessageComponent,
	Sms: NewSmsPanelComponent,
	NewSettlement: NewSettlementComponent,
	NewSignature: NewSignatureComponent,
	TermsImport: TermsImportComponent,
	SurveyLink: NewEmbeddedSurveyLinkComponent,
	NewAgency: NewAgencyComponent,
	RolePermissions: EditRolePermissionsComponent,
	NewImpaid: NewUnpaidCompanyComponent,
};
