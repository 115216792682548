import { EnvironmentEnum } from "a3dn-shared-lib";

export const environment = {
	name: "production",
	production: true,
	isMockEnabled: false,
	API_BASE_URL: "https://api.a3dn.com",
	AppInsightsInstrumentationKey: "c5c07890-4b7a-4636-8335-3e95f1d1f758",
	envEnum: EnvironmentEnum.Production,
};
