import { Injectable } from "@angular/core";
import { IPanelCommand, PanelWidthEnum } from "a3dn-shared-lib";
import { Subject } from "rxjs";
import { PanelInjectorComponent } from "../panel-injector/panel-injector.component";
import { ISideNavService } from "./I-side-nav";

@Injectable({
	providedIn: "root",
})
export class SideNavService implements ISideNavService {
	commands: IPanelCommand<any, any>[] = [];
	panelComponentInstance?: PanelInjectorComponent;
	closedCommandIndex: Subject<number> = new Subject<number>();

	constructor() {
		this.closedCommandIndex.subscribe((index) => {
			this.injectCommand(index + 1);
		});
	}

	openPanel(panelWidth: PanelWidthEnum, withCloseAlert?: boolean) {
		if (this.panelComponentInstance) {
			this.panelComponentInstance.openPanel();
			this.panelComponentInstance.panelWidth = panelWidth;
			this.panelComponentInstance.withCloseAlert = withCloseAlert;
		}
	}

	injectCommand(index: number) {
		this.panelComponentInstance?.injectCommand(index);
	}

	inject(commands: IPanelCommand<any, any>[]) {
		this.commands = commands;
		this.injectCommand(0);
	}
	closePanel() {
		this.panelComponentInstance?.closePanel();
	}
}
