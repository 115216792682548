import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { A3dnUserService, AppEnvironmentService } from "a3dn-shared-lib";
import { ApplicationInsightsService } from "./_core/application-insights/application-insights.service";
import { environment } from "../environments/environment";
import { SignalRService } from "ticket-domain-lib";
import { ModalNotificationSignalRService } from "a3dn-notifications-lib";
import { AppSignalRService } from "./app-signal-r.service";
import { SharedLoginService } from "a3dn-authentification-lib";

@Component({
	selector: "body[kt-root]",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	//
	constructor(
		private router: Router,
		private a3dnUserService: A3dnUserService,
		private appInsight: ApplicationInsightsService,
		private signalRService: SignalRService,
		modalNotificationSignalRService: ModalNotificationSignalRService,
		appSignalRService: AppSignalRService,
		appEnvironmentService: AppEnvironmentService,
		private sharedLoginService: SharedLoginService
	) {
		signalRService.setBaseUrl(environment.API_BASE_URL);
		modalNotificationSignalRService.setBaseUrl(environment.API_BASE_URL);
		modalNotificationSignalRService.start();
		appSignalRService.setBaseUrl(environment.API_BASE_URL);
		appSignalRService.start();
		appEnvironmentService.setEnvironment(environment.envEnum);
	}

	ngOnInit(): void {
		this.sharedLoginService.loggedInEvent.subscribe(() => {
			this.appInsight.start();
			try {
				this.signalRService.start();
			} catch (error) {
				console.log("Error while starting signalR service: " + error);
			}
		});
		this.setBaseUser();
		this.appInsight.start();
		try {
			this.signalRService.start();
		} catch (error) {
			console.log("Error while starting signalR service: " + error);
		}
	}

	setBaseUser() {
		if (localStorage.getItem("statutAbonnement") === "EssaiExpire") {
			localStorage.clear();
			this.router.navigate(["session-expired"]);
			return;
		}
		let token = localStorage.getItem("jwt");
		this.a3dnUserService.userToken = token;
	}
}
